import * as Types from "src/Constants/Types";

export const setFaces = (value: FacesType) => ({ type: Types.SET_FACES, value });

export const setScale = (value: number) => ({ type: Types.SET_SCALE, value });

export const setMovements = (value: DirectionType[]) => ({ type: Types.SET_MOVEMENTS, value });

export const setCursorClass = (value: string) => ({ type: Types.SET_CURSOR_CLASS, value });

export const setFullScreenStatus = (value: FullScreenStatusType | null) => ({ type: Types.SET_FULL_SCREEN_STATUS, value });

export const setCurrentRotationGroup = (value: Quaternion) => ({ type: Types.SET_CURRENT_ROTATION_GROUP, value });
