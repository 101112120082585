import { useEffect, useState } from "react";

import { AppBar, Toolbar, Stack } from "@mui/material";
import MenuIcon from "src/Components/Base/Header/MenuIcon";

import Logo from "src/Components/Base/Header/Logo";
import BtnContact from "src/Components/Base/Header/BtnContact";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import * as appActions from "src/Actions/appActions";
import * as animationsActions from "src/Actions/animationsActions";
import * as baseActions from "src/Actions/baseActions";

import LinksSections from "src/Components/Base/Header/LinksSections";

interface HeaderProps {
  isMobile: boolean;
  fullScreenStatus: FullScreenStatusType | null;
  setCurrentSection: (value: CurrentSectionType) => void;
  setIsSidebarOpen: (value: boolean) => void;
  setFullScreenStatus: (value: FullScreenStatusType | null) => void;
  t: (key: string) => ReactNode;
}

const Header = (props: HeaderProps) => {
  const { setIsSidebarOpen, fullScreenStatus, setFullScreenStatus } = props;
  const [isMobile, setIsMobile] = useState(props.isMobile);

  const handleSidebar = (value: boolean) => {
    setIsSidebarOpen(value);
  };

  useEffect(() => {
    setIsMobile(props.isMobile);
  }, [props.isMobile]);

  return (
    <AppBar
      position="absolute"
      sx={{ ...styles.appBar, boxShadow: "none", backgroundColor: fullScreenStatus === null ? "#FAFAFA" : "transparent" }}
    >
      <Toolbar>
        <Stack direction="row" sx={{ ...styles.menu, justifyContent: isMobile ? "space-between" : "flex-start" }}>
          <Logo onClick={() => setFullScreenStatus(null)} color={fullScreenStatus !== null ? "WHITE" : "BLACK"} />
          {!isMobile && <LinksSections />}
          {isMobile && <MenuIcon fullScreenStatus={fullScreenStatus} isSideBarOpen={false} handleOpen={() => handleSidebar(true)} />}
        </Stack>
        {!isMobile && <BtnContact fontSize="14px" />}
      </Toolbar>
    </AppBar>
  );
};

const styles = {
  appBar: {
    background: "transparent",
    zIndex: 100,
    transition: "all 0.8s ease !important",
  },
  menu: {
    flexGrow: 1,
    alignItems: "center",
    transition: "all 0.8s ease !important",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    isMobile: state.baseReducer.isMobile,
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setIsSidebarOpen: baseActions.setIsSidebarOpen,
  setCurrentSection: appActions.setCurrentSection,
  setFullScreenStatus: animationsActions.setFullScreenStatus,
};

export default connectWithDispatch(Header, mapStateToProps, mapDispatchToProps);
