import { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

import { Box, Button } from "@mui/material";

import initialVideo from "src/Assets/videos/initialVideo.mp4";
import initialVideoMobile from "src/Assets/videos/initialVideoMobile.mp4";

import * as appActions from "src/Actions/appActions";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import SkipNextIcon from "@mui/icons-material/SkipNext";

import Video from "src/Components/Video";

import { isMobile } from "react-device-detect";

interface InitialVideoProps {
  showInitialVideo: boolean;
  setShowInitialVideo: (value: boolean) => void;
  setShowCube: (value: boolean) => void;
}

const InitialVideo = (props: InitialVideoProps) => {
  const { showInitialVideo, setShowInitialVideo, setShowCube } = props;

  const [playing, setPlaying] = useState(showInitialVideo);
  const [showSkip, setShowSkip] = useState(false);

  const handleVideoEnd = () => {
    setPlaying(false);
    setShowCube(true);
    setTimeout(() => {
      setShowInitialVideo(false);
    }, 800);
  };

  const handleSkip = () => {
    setShowCube(true);
    setShowSkip(false);
    setPlaying(false);
    setShowInitialVideo(false);
  };

  const animationOpacity = useSpring({
    opacity: playing ? 1 : 0,
    config: { duration: 800 },
  });

  useEffect(() => {
    if (showInitialVideo) setTimeout(() => setShowSkip(true), 1500);
  }, [showInitialVideo]);

  return (
    <>
      {showInitialVideo && (
        <animated.div style={{ ...styles.containerAnimation, ...animationOpacity }}>
          <Video
            autoPlay={true}
            muted
            controls={false}
            style={{ width: "100%", objectFit: "cover", height: "100%" }}
            onEnded={handleVideoEnd}
            src={isMobile ? initialVideoMobile : initialVideo}
          />
          {showSkip && (
            <Box onClick={handleSkip} sx={{ position: "absolute", zIndex: 220, bottom: "1vw", right: "1vw" }}>
              <Button
                endIcon={<SkipNextIcon sx={{ color: "#999", marginRight: "-4px" }} />}
                sx={{ color: "#999", border: "1px solid #555", fontSize: "14px" }}
              >
                SKIP
              </Button>
            </Box>
          )}
        </animated.div>
      )}
    </>
  );
};

const styles = {
  containerAnimation: {
    width: "100%",
    height: "100%",
    color: "white",
    position: "absolute" as CSSProperties["position"],
    zIndex: 200,
    top: 0,
    left: 0,
    backgroundColor: "#000",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    isMobile: state.baseReducer.isMobile,
    showInitialVideo: state.appReducer.showInitialVideo,
  };
};

const mapDispatchToProps = {
  setShowInitialVideo: appActions.setShowInitialVideo,
  setShowCube: appActions.setShowCube,
};

export default connectWithDispatch(InitialVideo, mapStateToProps, mapDispatchToProps);
