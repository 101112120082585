import { Box, CircularProgress } from "@mui/material";

interface LoadingProps {
  show: boolean;
}

const Loading = (props: LoadingProps) => {
  const { show } = props;
  return (
    <>
      {show && (
        <Box style={styles.container}>
          <CircularProgress sx={{ color: "#fce72d" }} />
        </Box>
      )}
    </>
  );
};

const styles = {
  container: {
    width: "100vw",
    height: "100vh",
    position: "absolute" as CSSProperties["position"],
    top: 0,
    left: 0,
    zIndex: 100,
    backgroundColor: "#0000007a",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default Loading;
