import React from "react";
import { Grid, Checkbox } from "@mui/material";

import * as contactActions from "src/Actions/contactActions";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

interface OptionsProps {
  optionsValues: boolean[];
  setOptionsValues: (options: boolean[]) => void;
  t: (key: string) => string;
}

const Options = (props: OptionsProps) => {
  const { t, optionsValues, setOptionsValues } = props;

  const handleOnChange = (index: number) => {
    const newOptionsValues = [...optionsValues];
    newOptionsValues[index] = !newOptionsValues[index];
    setOptionsValues(newOptionsValues);
  };

  return (
    <Grid container sx={{ mt: { xs: 0, sm: 2, md: 2, lg: 4, xl: 4 } }}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((option, index) => {
        return (
          <Grid key={index} sx={{ mt: { xs: 0, sm: 0, md: 0, lg: 2, xl: 2 }, fontSize: "16px" }} item xs={12} sm={6} lg={4}>
            <Checkbox color="info" checked={optionsValues[option]} onChange={() => handleOnChange(option)} />
            <span style={{ cursor: "pointer" }} onClick={() => handleOnChange(option)}>
              {t(`sections.contact.steps.options.option_${option}`)}
            </span>
          </Grid>
        );
      })}
    </Grid>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    optionsValues: state.contactReducer.optionsValues,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setOptionsValues: contactActions.setOptionsValues,
};

export default connectWithDispatch(Options, mapStateToProps, mapDispatchToProps);
