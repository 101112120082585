import Typography from "@mui/material/Typography";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

interface Textprops {
  children?: React.ReactNode;
  text?: string;
  id?: string;
  color?: string;
  t: (key: string) => string;
}

const Text = (props: Textprops) => {
  const { children = <></>, text, id, t, color = "#000" } = props;

  return (
    <Typography variant="body1" sx={{ ...styles.text, color: color }}>
      {text ? text : id ? t(id) : children}
    </Typography>
  );
};

const styles = {
  text: {
    lineHeight: { xs: "30px", sm: "30px", md: "35px", lg: "24px", xl: "1.9vw" },
    fontSize: { xs: "4.6512vw", sm: "20px", md: "25px", lg: "16px", xl: "1.5vw" },
    fontWeight: "400",
    userSelect: "none",
  },
};

export default connectWithDispatch(Text);
