import { Box, Stack } from "@mui/material";

import Title from "src/Components/Tags/Title";
import TitleSection from "src/Components/Tags/TitleSection";
import Text from "src/Components/Tags/Text";

const About = () => {
  return (
    <Stack sx={styles.container}>
      <TitleSection id="header.about" />
      <Box sx={styles.title}>
        <Title id="sections.about.title" />
      </Box>
      <Box sx={styles.text}>
        <Text id="sections.about.text" />
      </Box>
    </Stack>
  );
};

const styles = {
  container: {
    maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "54vw", xl: "54vw" },
    margin: 0,
    marginLeft: "2.5vw",
    marginRight: "2.5vw",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "100%",
  },
  title: {
    marginTop: "40px",
  },
  text: {
    marginTop: "60px",
  },
};

export default About;
