/* eslint-disable @typescript-eslint/no-explicit-any */
const muiTheme = {
  palette: {
    primary: {
      main: "#FFF",
    },
    secondary: {
      main: "#FCE72D",
      ligth: "#FCE72D",
      dark: "#fce414",
      contrastText: "#000",
    },
  },
  typography: {
    color: "#FFF",
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          "@media (min-width: 600px)": {
            paddingLeft: "2.7vw",
            paddingRight: "2.7vw",
          },
          "@media (max-width: 960px)": {
            paddingLeft: "1vw",
            paddingRight: "1vw",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          bottom: "-2em",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: any) => {
          return {
            borderRadius: 0,
            textTransform: "none",
            fontSize: "16px",
            lineHeight: "40px",
            minWidth: "6.292em",
            padding: 0,
            paddingLeft: "1em",
            paddingRight: "1em",
            transition: "all 0.4s ease",
            "&:hover": {
              ...(ownerState.color === "secondary" ? { backgroundColor: "#fcc52d" } : {}),
              ...(ownerState.variant === "text"
                ? { color: "#fcc52d", backgroundColor: "transparent" }
                : { color: "white", backgroundColor: "#000" }),
              ...(ownerState.color === "info" ? { backgroundColor: "transparent" } : {}),
            },
          };
        },
      },
    },
  },
};

export default muiTheme;
