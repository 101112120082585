import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";

interface CloseIconProps {
  color?: string;
  onClick: () => void;
}

const Close = (props: CloseIconProps) => {
  const { onClick, color } = props;
  return (
    <Box sx={styles.closeSection} onClick={() => onClick()}>
      <CloseIcon sx={{ color: color ? color : "#000", fontSize: "45px" }} />
    </Box>
  );
};

const styles = {
  closeSection: {
    userSelect: "none" as CSSProperties["userSelect"],
    position: "absolute" as CSSProperties["position"],
    top: 0,
    right: 0,
    margin: "1vw",
    cursor: "pointer",
    zIndex: 100,
  },
};

export default Close;
