import Typography from "@mui/material/Typography";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

interface H1props {
  children?: React.ReactNode;
  text?: string;
  id?: string;
  color?: string;
  t: (key: string) => string;
}

const Title = (props: H1props) => {
  const { children = <></>, text, id, t, color = "#000" } = props;

  return (
    <Typography variant="h1" sx={{ ...styles.title, color: color }}>
      {text ? text : id ? t(id) : children}
    </Typography>
  );
};

const styles = {
  title: {
    lineHeight: { xs: "39px", sm: "45px", md: "55px", lg: "2.86vw", xl: "4vw" },
    fontSize: { xs: "8.8372vw", sm: "36px", md: "55px", lg: "3.6vw", xl: "3.6vw" },
    fontWeight: "700",
    userSelect: "none",
  },
};

export default connectWithDispatch(Title);
