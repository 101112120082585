import movements from "src/Utils/movements";

const basicMovements: DirectionType[] = ["up", "down", "left", "right"];

const getMovementsTo = (nextIndex: number, indexFront: number, indexTop: number): DirectionType[] => {
  const nextMovements: DirectionType[] = [] as DirectionType[];

  const allMovements = (movements as MovementsType)[indexFront][indexTop];

  const nextMove = getNextMove(nextIndex, indexFront, indexTop);

  if (nextMove) nextMovements.push(nextMove);
  else {
    const randomMove: DirectionType = basicMovements[Math.floor(Math.random() * 4)];
    nextMovements.push(randomMove);
    const newIndexFront = allMovements[randomMove][0];
    const newIndexTop = allMovements[randomMove][1];
    const nextSecondMove = getNextMove(nextIndex, newIndexFront, newIndexTop);
    if (nextSecondMove) nextMovements.push(nextSecondMove);
  }

  return nextMovements;
};

const getNextMove = (nextIndex: number, indexFront: number, indexTop: number): DirectionType | undefined => {
  const allMovements = (movements as MovementsType)[indexFront][indexTop];

  return basicMovements.find((move) => allMovements[move][0] === nextIndex);
};

export default getMovementsTo;
