/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from "src/Constants/Types";
import * as Constants from "src/Constants/Constants";
import axios from "axios";

const server = axios.create({ withCredentials: true });

export const setForm = (value: FormType) => ({ type: Types.SET_FORM, value });

export const resetForm = () => ({ type: Types.RESET_FORM });

export const setFormIsLoading = (value: boolean) => ({ type: Types.SET_FORM_IS_LOADING, value });

export const setFormErrorGeneral = (value: FormErrorGeneralType) => ({ type: Types.SET_FORM_ERROR_GENERAL, value });

export const setOptionsValues = (value: boolean[]) => ({ type: Types.SET_OPTIONS_VALUES, value });

export const setCurrentStep = (value: number) => ({ type: Types.SET_CURRENT_STEP, value });

export const sendForm = (form: { [key: string]: string }, options: string) => {
  return async (dispatch: any) => {
    dispatch(setFormIsLoading(true));
    await server
      .post(`${Constants.BASE_URL}/contact`, { ...form, options })
      .then((response: any) => {
        console.log(response);
        dispatch(setFormErrorGeneral("success"));
        dispatch(setFormIsLoading(false));
        dispatch(resetForm());
      })
      .catch(() => {
        dispatch(setFormErrorGeneral("unexpected"));
        dispatch(setFormIsLoading(false));
        dispatch(resetForm());
      });
  };
};
