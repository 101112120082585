import { Box } from "@mui/material";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import * as appActions from "src/Actions/appActions";

import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import { isMobile } from "react-device-detect";

interface MuteProps {
  faces: FacesType;
  fullScreenStatus: FullScreenStatusType | null;
  mutedShowreel: boolean;
  currentFace: number;
  setMutedShowreel: (value: boolean) => void;
}

const Mute = (props: MuteProps) => {
  const { currentFace, mutedShowreel, setMutedShowreel, fullScreenStatus } = props;
  const faceShowreel = 0;

  const handleMute = () => setMutedShowreel(!mutedShowreel);

  return (
    <>
      {fullScreenStatus !== null && currentFace === faceShowreel && (
        <Box
          onClick={handleMute}
          sx={{ position: "absolute", bottom: isMobile ? 0 : "40px", right: isMobile ? "5px" : "35px", zIndex: 300 }}
        >
          {mutedShowreel && <VolumeOffIcon style={{ fontSize: "48px", color: "white" }} />}
          {!mutedShowreel && <VolumeUpIcon style={{ fontSize: "48px", color: "white" }} />}
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    mutedShowreel: state.appReducer.mutedShowreel,
    faces: state.animationsReducer.faces,
    currentFace: state.appReducer.currentFace,
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
  };
};

const mapDispatchToProps = {
  setMutedShowreel: appActions.setMutedShowreel,
};

export default connectWithDispatch(Mute, mapStateToProps, mapDispatchToProps);
