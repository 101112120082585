import * as Types from "../Constants/Types";
import { Quaternion } from "three";

const initialState: AnimationsReducerType = {
  faces: { front: 0, top: 1 },
  scale: 1.2,
  movements: [],
  cursorClass: "",
  fullScreenStatus: null,
  currentRotationGroup: new Quaternion(),
};

type SetFaceAction = { type: typeof Types.SET_FACES; value: FacesType };
type SetScaleAction = { type: typeof Types.SET_SCALE; value: number };
type SetMovementsAction = { type: typeof Types.SET_MOVEMENTS; value: DirectionType[] };
type SetCursorClassAction = { type: typeof Types.SET_CURSOR_CLASS; value: string };
type SetFullScreenStatusAction = { type: typeof Types.SET_FULL_SCREEN_STATUS; value: FullScreenStatusType | null };
type SetCurrentRotationGroupAction = { type: typeof Types.SET_CURRENT_ROTATION_GROUP; value: Quaternion };

type animationsReducerAction =
  | SetFaceAction
  | SetScaleAction
  | SetMovementsAction
  | SetCursorClassAction
  | SetFullScreenStatusAction
  | SetCurrentRotationGroupAction;

const animationsReducer = (state = initialState, action: animationsReducerAction) => {
  switch (action.type) {
    case Types.SET_FACES: {
      return { ...state, faces: action.value };
    }
    case Types.SET_SCALE: {
      return { ...state, scale: action.value };
    }
    case Types.SET_MOVEMENTS: {
      return { ...state, movements: action.value };
    }
    case Types.SET_CURSOR_CLASS: {
      return { ...state, cursorClass: action.value };
    }
    case Types.SET_FULL_SCREEN_STATUS: {
      return { ...state, fullScreenStatus: action.value };
    }
    case Types.SET_CURRENT_ROTATION_GROUP: {
      return { ...state, currentRotationGroup: action.value };
    }
    default:
      return state;
  }
};

export default animationsReducer;
