import { Box } from "@mui/material";

import miniCub from "src/Assets/images/miniCub.png";
import { isMobile } from "react-device-detect";

interface MiniCubeProps {
  handleClose: () => void;
}

const MiniCube = (props: MiniCubeProps) => {
  return (
    <Box className="noEffect" sx={{ ...styles.miniCub, ...(isMobile ? { width: "100%", bottom: 0 } : { bottom: 0 }) }}>
      <img
        onClick={() => props.handleClose()}
        src={miniCub}
        alt="Exit"
        style={{ userSelect: "none", width: "48px", margin: "1vw 1.5vw" }}
      />
    </Box>
  );
};

const styles = {
  miniCub: {
    position: "absolute",
    zIndex: 100,
    textAlign: "center",
    left: 0,
    cursor: "pointer",
    userSelect: "none",
  },
};

export default MiniCube;
