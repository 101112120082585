// export const IN: FullScreenStatusType = "IN" as FullScreenStatusType;
// export const OUT: FullScreenStatusType = "OUT" as FullScreenStatusType;
// export const OPEN: FullScreenStatusType = "OPEN" as FullScreenStatusType;
// export const HIDE: FullScreenStatusType = "HIDE" as FullScreenStatusType;
export const NONE: FullScreenStatusType = "NONE" as FullScreenStatusType;
//ZOOM_IN
export const STEP_ONE: FullScreenStatusType = "STEP_ONE" as FullScreenStatusType;
export const STEP_TWO: FullScreenStatusType = "STEP_TWO" as FullScreenStatusType;
export const STEP_THREE: FullScreenStatusType = "STEP_THREE" as FullScreenStatusType;
export const STEP_FOUR: FullScreenStatusType = "STEP_FOUR" as FullScreenStatusType;
export const STEP_FIVE: FullScreenStatusType = "STEP_FIVE" as FullScreenStatusType;
export const STEP_SIX: FullScreenStatusType = "STEP_SIX" as FullScreenStatusType;
// export const STEP_SHOW: FullScreenStatusType = "STEP_SHOW" as FullScreenStatusType;
