import Typography from "@mui/material/Typography";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

interface H1props {
  children?: React.ReactNode;
  text?: string;
  id?: string;
  color?: string;
  t: (key: string) => string;
}

const TitleSection = (props: H1props) => {
  const { children = <></>, text, id, t, color = "#000" } = props;

  return (
    <Typography variant="h2" sx={{ ...styles.titleSection, color: color }}>
      {text ? text : id ? t(id) : children}
    </Typography>
  );
};

const styles = {
  titleSection: {
    textTransform: "uppercase",
    fontSize: { xs: "3.7209vw", sm: "18px", md: "18px", lg: "20px", xl: "20px" },
    lineHeight: { xs: "24px", sm: "24px", md: "24px", lg: "30px", xl: "30px" },
    letterSpacing: { xs: "1px", sm: "1px", md: "1px", lg: "5px", xl: "5px" },
    fontWeight: "400",
    width: "100%",
    userSelect: "none",
  },
};

export default connectWithDispatch(TitleSection);
