import { useState, useEffect, Fragment } from "react";
import { Button, Box } from "@mui/material";

import * as appActions from "src/Actions/appActions";
import * as baseActions from "src/Actions/baseActions";
import * as animationsActions from "src/Actions/animationsActions";

import { STEP_ONE } from "src/Constants/Transitions";

import connectWithDispatch from "src/Hooks/connectWithDispatch";
import getMovementsTo from "src/Utils/getMovementsTo";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const links: CurrentSectionType[] = ["about", "office", "reel"];

interface LinksSectionsProps {
  textColor?: string;
  faces: FacesType;
  fullScreenStatus: FullScreenStatusType | null;
  setIsSidebarOpen: (value: boolean) => void;
  setCurrentSection: (value: CurrentSectionType) => void;
  setMovements: (movements: DirectionType[]) => void;
  setFullScreenStatus: (value: FullScreenStatusType | null) => void;
  t: (key: string) => ReactNode;
  setLunchReel: (value: boolean) => void;
}

const LinksSections = (props: LinksSectionsProps) => {
  const { t, setCurrentSection, setIsSidebarOpen, setMovements, faces, setFullScreenStatus, fullScreenStatus, setLunchReel } = props;
  const [textColor, setTextColor] = useState(props.textColor);

  const handleSection = (section: CurrentSectionType) => {
    setIsSidebarOpen(false);
    if (section !== "reel") {
      setTimeout(() => {
        setCurrentSection(section);
      }, 200);
    } else {
      if (fullScreenStatus === null) {
        if (faces.front !== 0) setMovements(getMovementsTo(0, faces.front, faces.top));
        setTimeout(() => setFullScreenStatus(STEP_ONE), 300);
      } else setLunchReel(true);
    }
  };

  useEffect(() => {
    if (fullScreenStatus !== null && !props.textColor) setTextColor("#fff");
    else setTextColor("#000");
  }, [fullScreenStatus]);

  return (
    <Box sx={styles.container}>
      {links.map((link, index) => (
        <Fragment key={index}>
          {
            <Button
              key={`link-${index}`}
              onClick={() => handleSection(link)}
              variant="text"
              color={"primary"}
              {...(link === "reel"
                ? { startIcon: <PlayCircleOutlineIcon sx={{ color: fullScreenStatus !== null ? "white" : "#AFB1B3" }} /> }
                : {})}
              sx={{
                ...(textColor ? { color: textColor } : {}),
                ...styles.link,
                ml: 1,
              }}
            >
              <span className="spanLink">{t(`header.${link}`)}</span>
            </Button>
          }
        </Fragment>
      ))}
    </Box>
  );
};

const styles = {
  container: {
    height: "100%",
    pl: { xs: 0, sm: 0, md: "2vw", lg: "2vw", xl: "2vw" },
  },
  link: {
    paddingLeft: { sm: "0.8em" },
    paddingRight: { sm: "1.6em" },
    fontSize: { xs: "25px", sm: "14px", md: "16px", lg: "16px", xl: "16px" },
    lineHeight: { xs: "100px", sm: "87px", md: "87px", lg: "87px", xl: "87px" },
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    faces: state.animationsReducer.faces,
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setIsSidebarOpen: baseActions.setIsSidebarOpen,
  setCurrentSection: appActions.setCurrentSection,
  setMovements: animationsActions.setMovements,
  setFullScreenStatus: animationsActions.setFullScreenStatus,
  setLunchReel: appActions.setLunchReel,
};

export default connectWithDispatch(LinksSections, mapStateToProps, mapDispatchToProps);
