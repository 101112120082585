import { Box } from "@mui/material";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import logo_wide_black from "src/Assets/images/CE_Logo_wide_black.svg";
import logo_wide_white from "src/Assets/images/CE_Logo_wide_white.svg";

import "./logo.css";

interface LogoProps {
  onClick: () => void;
  color: "WHITE" | "BLACK";
}

const Logo = (props: LogoProps) => {
  const { color } = props;

  const handleOnClick = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <Box onClick={handleOnClick} sx={styles.container}>
      <img className={"logo"} src={color === "WHITE" ? logo_wide_white : logo_wide_black} alt="Charles Elena" style={styles.noSelected} />
    </Box>
  );
};

const styles = {
  container: {
    marginTop: { xs: 0, sm: 0, md: 0, lg: "-12px", xl: "-12px" },
    marginLeft: { xs: "16px", sm: "16px", md: "16px", lg: 0, xl: 0 },
    width: { xs: "205px", sm: "180px", md: "205px", lg: "225px", xl: "225px" },
    display: "flex",
    cursor: "pointer",
    userSelect: "none" as CSSProperties["userSelect"],
  },

  noSelected: {
    width: "100%",
    userSelect: "none" as CSSProperties["userSelect"],
  },
};

export default connectWithDispatch(Logo);
