import { Box } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import * as appActions from "src/Actions/appActions";

import { STEP_ONE, STEP_TWO } from "src/Constants/Transitions";

import { isMobile } from "react-device-detect";
import imgLeftWhite from "src/Assets/images/arrow-left-white.png";

function PrevArrow() {
  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        padding: isMobile ? "7vw" : "4vw",
        userSelect: "none",
      }}
    >
      <img style={{ width: isMobile ? "10px" : "14px" }} src={imgLeftWhite} alt="" />
    </div>
  );
}

interface ArrowProps {
  currentFace: number;
  fullScreenStatus: FullScreenStatusType | null;
  setCurrentFace: (current: number) => void;
}

const ArrowLeft = (props: ArrowProps) => {
  const { setCurrentFace, fullScreenStatus } = props;

  const handleOnClick = () => {
    const currentFace = props.currentFace > 0 ? props.currentFace - 1 : 5;
    setCurrentFace(currentFace);
  };

  const animatedProps = useSpring({
    opacity: fullScreenStatus === null || fullScreenStatus === STEP_ONE || fullScreenStatus === STEP_TWO ? 0 : 1,
    config: { duration: 800 },
  });

  return (
    <Box className="noEffect" sx={{ ...styles.container }}>
      <animated.div onClick={handleOnClick} style={{ ...animatedProps, ...styles.arrow }}>
        <PrevArrow />
      </animated.div>
    </Box>
  );
};

const styles = {
  container: {
    position: "absolute",
    left: "0",
    width: { xs: "50vw", sm: "40vw", md: "40vw", lg: "24vw", xl: "24vw" },
    height: { xs: "50vw", sm: "40vw", md: "40vw", lg: "24vw", xl: "24vw" },
    transform: { xs: "translateX(-75%)", sm: "translateX(-65%)", md: "translateX(-65%)", lg: "translateX(-65%)", xl: "translateX(-65%)" },
    top: { xs: "calc(50% - 25vw)", sm: "calc(50% - 20vw)", md: "calc(50% - 20vw)", lg: "calc(50% - 12vw)", xl: "calc(50% - 12vw)" },
    userSelect: "none",
  },
  arrow: {
    cursor: "pointer",
    height: "100%",
    width: "100%",
    borderRadius: "300px",
    border: "1px solid #9f9f9f",
    userSelect: "none" as CSSProperties["userSelect"],
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    currentFace: state.appReducer.currentFace,
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
  };
};

const mapDispatchToProps = {
  setCurrentFace: appActions.setCurrentFace,
};

export default connectWithDispatch(ArrowLeft, mapStateToProps, mapDispatchToProps);
