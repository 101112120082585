import aboutVideo from "src/Assets/videos/aboutVideo.mp4";
import officeVideo from "src/Assets/videos/officeVideo.mp4";
import contactVideo from "src/Assets/videos/contactVideo.mp4";

import Box from "@mui/material/Box";

import Video from "src/Components/Video";

const sections = { about: aboutVideo, office: officeVideo, contact: contactVideo, reel: "" };

interface VideoProps {
  currentSection: CurrentSectionType;
}

const LeftPanelVideo = (props: VideoProps) => {
  const { currentSection } = props;

  return (
    <Box sx={{ position: "relative", width: { xs: 0, sm: 0, md: 0, lg: "12%", xl: "12%" } }}>
      <Box sx={styles.videoContainer}>
        {currentSection && (
          <Video muted autoPlay loop controls={false} style={{ ...styles.video, left: 0 }} src={sections[currentSection]}></Video>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  videoContainer: {
    display: { xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" },
    height: "100%",
    width: "100%",
  },
  video: {
    height: "100%",
    position: "absolute" as CSSProperties["position"],
  },
};

export default LeftPanelVideo;
