import { useSpring, animated } from "@react-spring/web";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

interface BackdropProps {
  currentSection: CurrentSectionType;
  isOpenedBaseSections: boolean;
}

const Backdrop = (props: BackdropProps) => {
  const { currentSection, isOpenedBaseSections } = props;

  const propsOpacityBtn = useSpring({
    opacity: isOpenedBaseSections ? 1 : 0,
    config: { duration: 500 },
  });

  return <>{currentSection !== null && <animated.div style={{ ...styles.backdrop, ...propsOpacityBtn }} />}</>;
};

const styles = {
  backdrop: {
    position: "absolute" as CSSProperties["position"],
    backgroundColor: "#000000e6",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 100,
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    currentSection: state.appReducer.currentSection,
    isOpenedBaseSections: state.appReducer.isOpenedBaseSections,
  };
};

export default connectWithDispatch(Backdrop, mapStateToProps);
