import "./index.css";

interface MenuIconProps {
  isSideBarOpen: boolean;
  fullScreenStatus: FullScreenStatusType | null;
  handleOpen: () => void;
}

const MenuIcon = (props: MenuIconProps) => {
  const { isSideBarOpen, fullScreenStatus } = props;

  return (
    <button style={styles.btn} onClick={props.handleOpen} className={`${isSideBarOpen ? "MenuIconOpen" : ""}`}>
      <i className={`MenuIconBars ${fullScreenStatus == null ? "MenuIconBarsLight" : "MenuIconBarsDark"}`}></i>
    </button>
  );
};

const styles = {
  btn: {
    marginRight: "16px",
    height: "40px",
    border: 0,
    backgroundColor: "transparent",
  },
};

export default MenuIcon;
