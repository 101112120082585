import { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import * as appActions from "src/Actions/appActions";
import * as baseActions from "src/Actions/baseActions";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

interface BtnContactProps {
  isMobile: boolean;
  fontSize?: string;
  width?: string;
  fullScreenStatus: FullScreenStatusType | null;
  setIsSidebarOpen: (value: boolean) => void;
  setCurrentSection: (value: CurrentSectionType) => void;
  t: (key: string) => ReactNode;
}

const BtnContact = (props: BtnContactProps) => {
  const { t, isMobile, setCurrentSection, setIsSidebarOpen, fontSize, width, fullScreenStatus } = props;

  const [color, setColor] = useState<string | null>(null);

  const handleSection = (section: CurrentSectionType) => {
    setIsSidebarOpen(false);
    setTimeout(() => {
      setCurrentSection(section);
    }, 200);
  };

  useEffect(() => {
    setColor(fullScreenStatus !== null ? "#fff" : null);
  }, [fullScreenStatus]);

  return (
    <Button
      onClick={() => handleSection("contact")}
      color={"inherit"}
      variant="outlined"
      className={fullScreenStatus !== null ? "btn-contact-white" : ""}
      sx={{
        ...styles.btn,
        ...(fontSize && isMobile ? { fontSize: fontSize } : {}),
        ...(width ? { width: width } : {}),
        ...(color ? { color: color } : {}),
      }}
    >
      {t("header.contact")}
      <ArrowOutwardIcon sx={{ ml: 1 }} />
    </Button>
  );
};

const styles = {
  btn: {
    height: { xs: "auto", lg: "100%" },
    fontSize: { sm: "15px", lg: "16px", xl: "16px" },
    minWidth: { sm: "150px", lg: "175px" },
    fontWeight: "bold",
    borderRadius: "20px",
  },
};
const mapStateToProps = (state: StateType) => {
  return {
    isMobile: state.baseReducer.isMobile,
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setCurrentSection: appActions.setCurrentSection,
  setIsSidebarOpen: baseActions.setIsSidebarOpen,
};

export default connectWithDispatch(BtnContact, mapStateToProps, mapDispatchToProps);
