import { useEffect } from "react";
import { isMobile as RDDisMobile } from "react-device-detect";
import { Box } from "@mui/material";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import BaseSections from "src/Components/BaseSections";
import SideDrawer from "src/Components/Base/Header/SideDrawer";
import Header from "src/Components/Base/Header";
import PartnersLogoAbsolute from "src/Components/Base/PartnersLogoAbsolute";
import Widget from "src/Components/Base/Widget";
import MainAnimation from "src/Components/MainAnimation";
import MainSections from "src/Components/MainSections";

import proportionalConversion from "src/Utils/proportionalConversion";

import * as baseActions from "src/Actions/baseActions";
import * as animationsActions from "src/Actions/animationsActions";

import { STEP_ONE, STEP_SIX } from "src/Constants/Transitions";

interface BaseProps {
  isMobile: boolean;
  scale: number;
  fullScreenStatus: FullScreenStatusType | null;
  setScale: (scale: number) => void;
  setIsMobile: (isMobile: boolean) => void;
  setIsSidebarOpen: (value: boolean) => void;
}

const Base = (props: BaseProps) => {
  const { setIsMobile, isMobile, setScale, fullScreenStatus, setIsSidebarOpen } = props;

  useEffect(() => {
    const handleResize = () => {
      setScale(proportionalConversion(window.innerWidth));
      setIsMobile(RDDisMobile || window.innerWidth <= 780);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth, isMobile]);

  return (
    <>
      <Box sx={{ height: "100%", backgroundColor: "#FAFAFA" }}>
        <Box sx={{ padding: "0!important", height: "100%", display: "flex", flexDirection: "column" }}>
          <Header />
          <BaseSections />
          <SideDrawer onClick={() => setIsSidebarOpen(false)} />
          <MainSections />
          {fullScreenStatus === null && !isMobile && <PartnersLogoAbsolute />}
          {fullScreenStatus === null && !isMobile && <Widget />}
          {(fullScreenStatus === null || fullScreenStatus === STEP_ONE || fullScreenStatus === STEP_SIX) && <MainAnimation />}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    scale: state.animationsReducer.scale,
    isMobile: state.baseReducer.isMobile,
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setIsMobile: baseActions.setIsMobile,
  setIsSidebarOpen: baseActions.setIsSidebarOpen,
  setScale: animationsActions.setScale,
};

export default connectWithDispatch(Base, mapStateToProps, mapDispatchToProps);
