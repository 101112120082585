import * as Types from "src/Constants/Types";

export const setCurrentSection = (value: CurrentSectionType) => ({ type: Types.SET_CURRENT_SECTION, value });

export const setIsOpenedBaseSections = (value: boolean) => ({ type: Types.SET_IS_OPENED_BASE_SECTIONS, value });

export const setMutedShowreel = (value: boolean) => ({ type: Types.SET_MUTED_SHOWREEL, value });

export const setCurrentFace = (value: number) => ({ type: Types.SET_CURRENT_FACE, value });

export const setLunchReel = (value: boolean) => ({ type: Types.SET_LUNCH_REEL, value });

export const setShowInitialVideo = (value: boolean) => ({ type: Types.SET_SHOW_INITIAL_VIDEO, value });

export const setShowCube = (value: boolean) => ({ type: Types.SET_SHOW_CUBE, value });
