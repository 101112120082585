const movements: MovementsType = {
  0: {
    1: { up: [4, 0], down: [1, 5], left: [3, 1], right: [2, 1] },
    2: { up: [3, 0], down: [2, 5], left: [1, 2], right: [4, 2] },
    3: { up: [2, 0], down: [3, 5], left: [4, 3], right: [1, 3] },
    4: { up: [1, 0], down: [4, 5], left: [2, 4], right: [3, 4] },
  },
  1: {
    0: { up: [5, 1], down: [0, 4], left: [2, 0], right: [3, 0] },
    2: { up: [3, 1], down: [2, 4], left: [5, 2], right: [0, 2] },
    5: { up: [0, 1], down: [5, 4], left: [3, 5], right: [2, 5] },
    3: { up: [2, 1], down: [3, 4], left: [0, 3], right: [5, 3] },
  },
  2: {
    0: { up: [5, 2], down: [0, 3], left: [4, 0], right: [1, 0] },
    1: { up: [4, 2], down: [1, 3], left: [0, 1], right: [5, 1] },
    4: { up: [1, 2], down: [4, 3], left: [5, 4], right: [0, 4] },
    5: { up: [0, 2], down: [5, 3], left: [1, 5], right: [4, 5] },
  },
  3: {
    0: { up: [5, 3], down: [0, 2], left: [1, 0], right: [4, 0] },
    1: { up: [4, 3], down: [1, 2], left: [5, 1], right: [0, 1] },
    4: { up: [1, 3], down: [4, 2], left: [0, 4], right: [5, 4] },
    5: { up: [0, 3], down: [5, 2], left: [4, 5], right: [1, 5] },
  },
  4: {
    0: { up: [5, 4], down: [0, 1], left: [3, 0], right: [2, 0] },
    2: { up: [3, 4], down: [2, 1], left: [0, 2], right: [5, 2] },
    3: { up: [2, 4], down: [3, 1], left: [0, 3], right: [0, 3] },
    5: { up: [0, 4], down: [5, 1], left: [2, 5], right: [3, 5] },
  },
  5: {
    1: { up: [4, 5], down: [1, 0], left: [2, 1], right: [3, 1] },
    2: { up: [3, 5], down: [2, 0], left: [4, 2], right: [1, 2] },
    4: { up: [1, 5], down: [4, 0], left: [3, 4], right: [2, 4] },
    3: { up: [2, 5], down: [3, 0], left: [1, 3], right: [4, 3] },
  },
};

export default movements;
