import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import { Stack, Box } from "@mui/material";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import * as appActions from "src/Actions/appActions";
import * as contactActions from "src/Actions/contactActions";

import Close from "src/Components/Base/Header/Close";

import LeftPanelVideo from "src/Components/BaseSections/LeftPanelVideo";

import Logo from "src/Components/Base/Header/Logo";

import About from "./About";
import Office from "./Office";
import Contact from "./Contact";
import Footer from "./Footer";
import Backdrop from "./Backdrop";

interface BaseSectionsProps {
  children: ReactNode;
  currentSection: CurrentSectionType;
  resetForm: () => void;
  setCurrentStep: (currentStep: number) => void;
  setCurrentSection: (currentSection: CurrentSectionType) => void;
  setFormErrorGeneral: (formErrorGeneral: FormErrorGeneralType) => void;
  isOpenedBaseSections: boolean;
  setIsOpenedBaseSections: (isOpenedBaseSections: boolean) => void;
}

const BaseSections = (props: BaseSectionsProps) => {
  const { isOpenedBaseSections, setIsOpenedBaseSections } = props;

  useEffect(() => {
    if (props.currentSection === null) {
      props.resetForm();
      props.setCurrentStep(0);
    }
    if (props.currentSection) setTimeout(() => setIsOpenedBaseSections(true), 200);
  }, [props.currentSection]);

  const boxAnimation = useSpring({
    from: { transform: `translateY(100%)`, opacity: 1 },
    to: { transform: `translateY(${isOpenedBaseSections ? 0 : 100}%)`, opacity: 1 },
    config: { tension: 80, friction: 12 },
  });

  const closeSection = () => {
    setIsOpenedBaseSections(false);
    props.setFormErrorGeneral(null);
    setTimeout(() => props.setCurrentSection(null), 400);
  };

  return (
    <>
      <Backdrop isOpenedBaseSections={isOpenedBaseSections} />
      {props.currentSection !== null && (
        <Box
          sx={{
            ...styles.container,
            marginTop: { lg: "5vh", xl: "5vh" },
            marginLeft: { lg: "5vw", xl: "5vw" },
            width: { xs: `100vw`, sm: `100vw`, md: `100vw`, lg: `90vw`, xl: `90vw` },
            height: {
              xs: `100svh`,
              sm: `100svh`,
              md: `100svh`,
              lg: "90vh",
              xl: "90vh",
            },
          }}
        >
          <animated.div
            style={{
              ...boxAnimation,
              ...styles.subContainer,
            }}
          >
            <Box sx={styles.containerResponsive}>
              <Stack sx={{ flexGrow: 1 }} direction={"column"}>
                <Stack direction={"row"} sx={{ flex: 1 }}>
                  <LeftPanelVideo currentSection={props.currentSection} />
                  <Box sx={styles.containerSection}>
                    <Box sx={styles.containerLogo}>
                      <Logo color="BLACK" onClick={closeSection} />
                    </Box>
                    <Close onClick={closeSection} />
                    <>
                      {props.currentSection === "about" && <About />}
                      {props.currentSection === "office" && <Office />}
                      {props.currentSection === "contact" && <Contact />}
                    </>
                  </Box>
                </Stack>
                <Footer />
              </Stack>
            </Box>
          </animated.div>
        </Box>
      )}
    </>
  );
};

const styles = {
  container: {
    position: "absolute" as CSSProperties["position"],
    width: "100vw",
    top: 0,
    left: 0,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column" as CSSProperties["flexDirection"],
    zIndex: 110,
  },
  subContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column" as CSSProperties["flexDirection"],
  },
  containerResponsive: {
    display: "flex",
    height: "100%",
    padding: "0px!important",
    position: "relative",
    backgroundColor: "#FFF",
  },
  containerSection: {
    flex: 1,
    height: "100%",
    zIndex: 2,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginLeft: { xs: "2vw", sm: "2vw", md: "2vw", lg: 0, xl: 0 },
    marginRight: { xs: "2vw", sm: "2vw", md: "2vw", lg: 0, xl: 0 },
  },
  containerLogo: {
    userSelect: "none" as CSSProperties["userSelect"],
    position: "absolute" as CSSProperties["position"],
    top: 0,
    left: 0,
    margin: "1vw",
    cursor: "pointer",
    zIndex: 100,
    height: "56px",
    display: { xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" },
    alignItems: "center",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    currentSection: state.appReducer.currentSection,
    isOpenedBaseSections: state.appReducer.isOpenedBaseSections,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  resetForm: contactActions.resetForm,
  setCurrentStep: contactActions.setCurrentStep,
  setCurrentSection: appActions.setCurrentSection,
  setFormErrorGeneral: contactActions.setFormErrorGeneral,
  setIsOpenedBaseSections: appActions.setIsOpenedBaseSections,
};

export default connectWithDispatch(BaseSections, mapStateToProps, mapDispatchToProps);
