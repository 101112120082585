import partners from "src/Assets/images/partners.png";
import partners_white from "src/Assets/images/partners_white.png";

interface PartnersLogoProps {
  color: "WHITE" | "BLACK";
}
const PartnersLogo = (props: PartnersLogoProps) => {
  const { color } = props;
  return (
    <img
      src={color === "WHITE" ? partners_white : partners}
      alt=""
      style={{ width: "50vw", maxWidth: "220px", justifyContent: "center", margin: "3vh auto", display: "flex" }}
    />
  );
};

export default PartnersLogo;
