import { useRef, useEffect, useState } from "react";

interface VideoProps {
  onEnded?: () => void;
  ref?: React.RefObject<HTMLVideoElement>;
  controls?: boolean;
  src?: string;
  loop?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  style: React.CSSProperties;
  percent?: number;
}

const Video = (props: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [playing, setPlaying] = useState(false);
  const [promise, setPromise] = useState<Promise<void> | undefined>();

  useEffect(() => {
    const video = videoRef.current;

    if (video) setPromise(video.play());

    const handleVisibilityChange = () => {
      if (video) {
        if (document.visibilityState === "hidden") {
          if (promise && !video.paused) {
            promise
              .then(() => {
                video.pause();
                setPlaying(false);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }

        if (document.visibilityState === "visible") {
          if (video.paused || !playing) {
            setPromise(video.play());
            setPlaying(true);
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [playing]);

  const filteredProps = { ...props };

  return <video ref={videoRef} playsInline {...filteredProps} />;
};

export default Video;
