const proportionalConversion = (windowWidth: number) => {
  const minValue = 375;
  const maxValue = 1536;
  const outputMin = 0.8;
  const outputMax = 1.2;

  if (windowWidth > maxValue) {
    return outputMax;
  } else if (windowWidth < minValue) {
    return outputMin;
  } else {
    const proportion = (outputMax - outputMin) / (maxValue - minValue);
    return outputMin + (windowWidth - minValue) * proportion;
  }
};

export default proportionalConversion;
