import { Box } from "@mui/material";

const EmDash = () => {
  return <Box sx={styles.dash}></Box>;
};

const styles = {
  dash: {
    borderBottom: "1px solid #828282",
    height: { xs: "6.51vw", sm: "3.1vw", md: "2.09vw", lg: "18px", xl: "18px" },
    width: { xs: "3.26vw", sm: "3vw", md: "2.09vw", lg: "14px", xl: "14px" },
  },
};

export default EmDash;
