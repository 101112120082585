const FullScreenLoading = () => {
  return (
    <div style={styles.loading}>
      <div style={styles.logo}></div>
    </div>
  );
};

const styles = {
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  logo: {
    width: "50px",
    height: "50px",
    border: "8px solid #ddd",
    borderTopColor: "#fce72d",
    borderRadius: "50%",
    animation: "spin 0.8s linear infinite",
  },
};

export default FullScreenLoading;
