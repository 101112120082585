import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import Template from "./Template";

import * as appActions from "src/Actions/appActions";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import { STEP_FOUR, STEP_FIVE, STEP_SIX } from "src/Constants/Transitions";

interface CarruselProps {
  faces: FacesType;
  lunchReel: boolean;
  fullScreenStatus: FullScreenStatusType | null;
  currentFace: number;
  setCurrentFace: (current: number) => void;
  setLunchReel: (lunchReel: boolean) => void;
}

const Carrusel = (props: CarruselProps) => {
  const { faces, setCurrentFace, setLunchReel, fullScreenStatus } = props;

  const [localCurrentFace, setLocalCurrentFace] = useState(faces.front);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current: number) => {
      setCurrentFace(current);
      setLocalCurrentFace(current);
    },
  };

  const slider = useRef<Slider>(null);

  useEffect(() => {
    if (fullScreenStatus !== STEP_FOUR && fullScreenStatus !== STEP_FIVE && fullScreenStatus !== STEP_SIX)
      if (slider.current) {
        setCurrentFace(faces.front);
        setLocalCurrentFace(faces.front);
        slider.current.slickGoTo(faces.front);
      }
  }, [fullScreenStatus, faces.front]);

  useEffect(() => {
    if (props.lunchReel && slider.current) {
      slider.current.slickGoTo(0);
      setLunchReel(false);
    }
  }, [props.lunchReel]);

  useEffect(() => {
    if (slider.current && localCurrentFace !== props.currentFace) {
      slider.current.slickGoTo(props.currentFace);
      setLocalCurrentFace(props.currentFace);
    }
  }, [props.currentFace, localCurrentFace]);

  return (
    <Slider ref={slider} {...settings}>
      <Template face={0} />
      <Template face={1} />
      <Template face={2} />
      <Template face={3} />
      <Template face={4} />
      <Template face={5} />
    </Slider>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    faces: state.animationsReducer.faces,
    lunchReel: state.appReducer.lunchReel,
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
    currentFace: state.appReducer.currentFace,
  };
};

const mapDispatchToProps = {
  setCurrentFace: appActions.setCurrentFace,
  setLunchReel: appActions.setLunchReel,
};

export default connectWithDispatch(Carrusel, mapStateToProps, mapDispatchToProps);
