import { Box, TextField, Grid } from "@mui/material";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

interface FormProps {
  wasSubmitted: boolean;
  form: FormType;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  t: (key: string) => string;
}

const Form = (props: FormProps) => {
  const { wasSubmitted, t, form, handleOnChange } = props;

  return (
    <Box sx={styles.containerGrid}>
      <Grid container spacing={{ lg: 2, md: 2, sm: 1, xs: 0 }} sx={styles.grid}>
        <Grid sx={{ mt: { xs: 2, sm: 0, md: 0, lg: 0, xl: 0 }, mb: { xs: 2 } }} item xs={12} sm={6} lg={4}>
          <TextField
            fullWidth
            color="info"
            name="name"
            value={form.name.value}
            onChange={handleOnChange}
            required
            id="name"
            label={t("sections.contact.steps.form.name")}
            variant="standard"
            error={form.name.error}
            helperText={form.name.error && wasSubmitted ? t(`errors.${form.name.helperText}`) : ""}
          />
        </Grid>
        <Grid sx={{ mt: { xs: 2, sm: 0, md: 0, lg: 0, xl: 0 }, mb: { xs: 1 } }} item xs={12} sm={6} lg={4}>
          <TextField
            fullWidth
            color="info"
            name="email"
            value={form.email.value}
            onChange={handleOnChange}
            required
            id="email"
            label={t("sections.contact.steps.form.email")}
            variant="standard"
            error={form.email.error}
            helperText={form.email.error && wasSubmitted ? t(`errors.${form.email.helperText}`) : ""}
          />
        </Grid>
        <Grid sx={{ mt: { xs: 2, sm: 0, md: 0, lg: 0, xl: 0 }, mb: { xs: 1 } }} item xs={12} sm={6} lg={4}>
          <TextField
            fullWidth
            color="info"
            name="phone"
            value={form.phone.value}
            onChange={handleOnChange}
            required
            id="phone"
            label={t("sections.contact.steps.form.phone")}
            variant="standard"
            error={form.phone.error}
            helperText={form.phone.error && wasSubmitted ? t(`errors.${form.phone.helperText}`) : ""}
          />
        </Grid>
        <Grid sx={{ mt: { xs: 2, sm: 0, md: 0, lg: 0, xl: 0 }, mb: { xs: 1 } }} item xs={12} sm={12} lg={12}>
          <TextField
            fullWidth
            color="info"
            name="organization"
            value={form.organization.value}
            onChange={handleOnChange}
            id="organization"
            label={t("sections.contact.steps.form.organization")}
            variant="standard"
            error={form.organization.error}
            helperText={form.organization.error && wasSubmitted ? t(`errors.${form.organization.helperText}`) : ""}
          />
        </Grid>
        <Grid sx={{ mb: { xs: 2 } }} item xs={12} sm={12} lg={12}>
          <TextField
            fullWidth
            color="info"
            name="description"
            value={form.description.value}
            onChange={handleOnChange}
            multiline
            rows={3}
            id="description"
            label={t("sections.contact.steps.form.description")}
            variant="standard"
            error={form.description.error}
            helperText={form.description.error && wasSubmitted ? t(`errors.${form.description.helperText}`) : ""}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  containerGrid: {
    marginTop: { xs: "2vh", sm: "2vh", md: "4vh", lg: "6vh", xl: "6vh" },
  },
  grid: {
    height: { xs: "auto", sm: "auto", md: "auto", lg: "300px", xl: "300px" },
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    form: state.contactReducer.form,
  };
};

export default connectWithDispatch(Form, mapStateToProps);
