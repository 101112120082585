import { Fragment } from "react";
import { Button, Box } from "@mui/material";

import * as appActions from "src/Actions/appActions";
import * as baseActions from "src/Actions/baseActions";
import * as animationsActions from "src/Actions/animationsActions";

import { STEP_ONE } from "src/Constants/Transitions";

import connectWithDispatch from "src/Hooks/connectWithDispatch";
import getMovementsTo from "src/Utils/getMovementsTo";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const links: CurrentSectionType[] = ["about", "office", "reel"];

interface LinksSectionsProps {
  textColor?: string;
  faces: FacesType;
  fullScreenStatus: FullScreenStatusType | null;
  setIsSidebarOpen: (value: boolean) => void;
  setCurrentSection: (value: CurrentSectionType) => void;
  setMovements: (movements: DirectionType[]) => void;
  setFullScreenStatus: (value: FullScreenStatusType | null) => void;
  t: (key: string) => ReactNode;
  setLunchReel: (value: boolean) => void;
}

const LinksSections = (props: LinksSectionsProps) => {
  const { t, setCurrentSection, setIsSidebarOpen, setMovements, faces, setFullScreenStatus, fullScreenStatus, setLunchReel } = props;

  const handleSection = (section: CurrentSectionType) => {
    setIsSidebarOpen(false);
    if (section !== "reel") {
      setTimeout(() => {
        setCurrentSection(section);
      }, 200);
    } else {
      if (fullScreenStatus === null) {
        if (faces.front !== 0) setMovements(getMovementsTo(0, faces.front, faces.top));
        setTimeout(() => {
          setFullScreenStatus(STEP_ONE);
        }, 300);
      } else setLunchReel(true);
    }
  };

  return (
    <Box>
      {links.map((link, index) => (
        <Fragment key={index}>
          {
            <Button key={`link-${index}`} onClick={() => handleSection(link)} variant="text" sx={styles.link}>
              {t(`header_mobile.${link}`)}
            </Button>
          }
        </Fragment>
      ))}
      <Box sx={styles.containerLinkContact}>
        <Button
          onClick={() => handleSection("contact")}
          color={"inherit"}
          variant="outlined"
          sx={styles.linkContact}
          endIcon={<ArrowOutwardIcon sx={{ color: "#fce72d", fontSize: "28px!important" }} />}
        >
          {t(`header_mobile.contact`)}
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  link: {
    marginTop: "15px",
    width: "100%",
    color: "#fff",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "500",
    lineHeight: "40px",
    textTransform: "uppercase",
  },
  containerLinkContact: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  linkContact: {
    marginTop: "51px",
    padding: "15px 50px",
    fontSize: "20px",
    lineHeight: "28px",
    textTransform: "uppercase",
    color: "#fff",
    borderRadius: "76px",
    border: "2.21px solid white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    faces: state.animationsReducer.faces,
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setIsSidebarOpen: baseActions.setIsSidebarOpen,
  setCurrentSection: appActions.setCurrentSection,
  setMovements: animationsActions.setMovements,
  setFullScreenStatus: animationsActions.setFullScreenStatus,
  setLunchReel: appActions.setLunchReel,
};

export default connectWithDispatch(LinksSections, mapStateToProps, mapDispatchToProps);
