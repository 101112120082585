import widget from "src/Assets/images/widget.png";

const Widget = () => {
  return (
    <img
      src={widget}
      alt=""
      style={{
        zIndex: 50,
        bottom: 0,
        right: 0,
        position: "absolute",
        width: "65px",
        userSelect: "none",
        marginBottom: "2.48vh",
        marginRight: "2.7vw",
      }}
    />
  );
};

export default Widget;
