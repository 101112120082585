//Base
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_MOBILE = "SET_IS_MOBILE";
export const SET_IS_SIDEBAR_OPEN = "SET_IS_SIDEBAR_OPEN";

//Animations
export const SET_FACES = "SET_FACES";
export const SET_SCALE = "SET_SCALE";
export const SET_MOVEMENTS = "SET_MOVEMENTS";
export const SET_CURSOR_CLASS = "SET_CURSOR_CLASS";
export const SET_FULL_SCREEN_STATUS = "SET_FULL_SCREEN_STATUS";
export const SET_CURRENT_ROTATION_GROUP = "SET_CURRENT_ROTATION_GROUP";

//App
export const SET_MODE = "SET_MODE";
export const SET_CURRENT_SECTION = "SET_CURRENT_SECTION";
export const SET_IS_OPENED_BASE_SECTIONS = "SET_IS_OPENED_BASE_SECTIONS";
export const SET_MUTED_SHOWREEL = "SET_MUTED_SHOWREEL";
export const SET_CURRENT_FACE = "SET_CURRENT_FACE";
export const SET_LUNCH_REEL = "SET_LUNCH_REEL";
export const SET_SHOW_INITIAL_VIDEO = "SET_SHOW_INITIAL_VIDEO";
export const SET_SHOW_CUBE = "SET_SHOW_CUBE";

//Contact
export const RESET_FORM = "RESET_FORM";
export const SET_FORM = "SET_FORM";
export const SET_FORM_IS_LOADING = "SET_FORM_IS_LOADING";
export const SET_FORM_ERROR_GENERAL = "SET_FORM_ERROR_GENERAL";
export const SET_OPTIONS_VALUES = "SET_OPTIONS_VALUES";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
