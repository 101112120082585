import connectWithDispatch from "src/Hooks/connectWithDispatch";

import { Box } from "@mui/material";

import LinksSectionsMobile from "src/Components/Base/Header/LinksSectionsMobile";
import Close from "src/Components/Base/Header/Close";

import * as baseActions from "src/Actions/baseActions";
import * as animationsActions from "src/Actions/animationsActions";

import PartnersLogo from "src/Components/Base/PartnersLogo";

import Logo from "src/Components/Base/Header/Logo";

interface SideDrawerProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
  setFullScreenStatus: (value: FullScreenStatusType | null) => void;
}

const SideDrawer = (props: SideDrawerProps) => {
  const { isSidebarOpen, setIsSidebarOpen, setFullScreenStatus } = props;

  const closeSection = () => {
    setIsSidebarOpen(false);
    setFullScreenStatus(null);
  };

  return (
    <Box sx={isSidebarOpen ? styles.sideDrawerOpen : styles.sideDrawer}>
      <Close onClick={closeSection} color="#fff" />
      <Box sx={styles.container}>
        <Box sx={{ height: "56px", width: "100%", alignItems: "center", display: "flex" }}>
          <Logo color="WHITE" onClick={closeSection} />
        </Box>
        <LinksSectionsMobile />
        <PartnersLogo color={"WHITE"} />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  },
  sideDrawer: {
    height: "100%",
    boxShadow: "1px 0 7px rgba(0, 0, 0, 0.5)",
    position: "fixed",
    background: "#000",
    top: "0",
    left: "0",
    width: "100vw",
    zIndex: "200",
    transform: "translateX(-100%)",
    transition: "transform 0.3s ease-out",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sideDrawerOpen: {
    height: "100%",
    background: "#000",
    boxShadow: "1px 0 7px rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    zIndex: "200",
    transition: "transform 0.3s ease-out",
    transform: "translateX(0)",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    isSidebarOpen: state.baseReducer.isSidebarOpen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setIsSidebarOpen: baseActions.setIsSidebarOpen,
  setFullScreenStatus: animationsActions.setFullScreenStatus,
};

export default connectWithDispatch(SideDrawer, mapStateToProps, mapDispatchToProps);
