import React from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import muiTheme from "./Configs/muiTheme";
import useImagePreloader from "./Hooks/useImagePreloader";
import FullScreenLoading from "./Components/FullScreenLoading";
import Base from "src/Components/Base";

// import innovateVideo from "src/Assets/videos/innovate.mp4";
// import adaptVideo from "src/Assets/videos/adapt.mp4";
// import tranformVideo from "src/Assets/videos/transform.mp4";
// import experienceVideo from "src/Assets/videos/experience.mp4";
// import immerseVideo from "src/Assets/videos/immerse.mp4";
// import reelVideo from "src/Assets/videos/reel.mp4";
// import aboutVideo from "src/Assets/videos/aboutVideo.mp4";

import InitialVideo from "src/Components/InitialVideo";

const App = () => {
  const mdTheme = createTheme(muiTheme as ThemeOptions);

  const media: MediaPreloadType[] = [];

  const { imagesPreloaded } = useImagePreloader(media);

  return (
    <>
      <InitialVideo />
      {!imagesPreloaded && <FullScreenLoading />}
      {imagesPreloaded && (
        <ThemeProvider theme={mdTheme}>
          <CssBaseline />
          <Base />
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
