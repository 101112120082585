import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";

import thunk from "redux-thunk";

import baseReducer from "src/Reducers/baseReducer";
import animationsReducer from "src/Reducers/animationsReducer";
import appReducer from "src/Reducers/appReducer";
import contactReducer from "src/Reducers/contactReducer";

const rootReducer = combineReducers({
  baseReducer,
  animationsReducer,
  appReducer,
  contactReducer,
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export default store;
