import { useState, useEffect } from "react";

import { Box, Button, Alert } from "@mui/material";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

interface BtnsProps {
  currentStep: number;
  backStep: () => void;
  nextStep: () => void;
  t: (key: string) => string;
  formErrorGeneral: FormErrorGeneralType;
  handleOnSubmit: (event: React.MouseEvent<HTMLElement>) => void;
}

const Btns = (props: BtnsProps) => {
  const { t, currentStep, backStep, nextStep, handleOnSubmit } = props;
  const [formErrorGeneral, setFormErrorGeneral] = useState<FormErrorGeneralType>(props.formErrorGeneral);

  useEffect(() => {
    if (props.formErrorGeneral) setFormErrorGeneral(props.formErrorGeneral);
  }, [props.formErrorGeneral]);

  return (
    <>
      {currentStep === 1 && (
        <Box>
          <Button onClick={backStep} sx={{ borderRadius: "20px", mt: 2, mr: 2 }} color={"inherit"} variant="outlined">
            {t("sections.contact.back")}
          </Button>
          <Button onClick={handleOnSubmit} sx={{ borderRadius: "20px", mt: 2 }} color={"inherit"} variant="outlined">
            {t("sections.contact.send")}
          </Button>
          {formErrorGeneral === "success" && (
            <Alert sx={{ mt: 1 }} severity="success">
              {t(`errors.${formErrorGeneral}`)}
            </Alert>
          )}
          {formErrorGeneral === "unexpected" && (
            <Alert sx={{ mt: 1 }} severity="error">
              {t(`errors.${formErrorGeneral}`)}
            </Alert>
          )}
        </Box>
      )}
      {currentStep === 0 && (
        <Box sx={{ mb: 1 }}>
          <Button onClick={nextStep} sx={{ borderRadius: "20px", mt: 2 }} color={"inherit"} variant="outlined">
            {t("sections.contact.next")}
          </Button>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    formErrorGeneral: state.contactReducer.formErrorGeneral,
  };
};

export default connectWithDispatch(Btns, mapStateToProps);
