import React, { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { isMobile as RDDIsMobile } from "react-device-detect";

import { useSpring, animated } from "@react-spring/web";

import adaptVideo from "src/Assets/videos/adaptFull.mp4";
import innovateVideo from "src/Assets/videos/innovateFull.mp4";
import tranformVideo from "src/Assets/videos/transformFull.mp4";
import experienceVideo from "src/Assets/videos/experienceFull.mp4";
import immerseVideo from "src/Assets/videos/immerseFull.mp4";
import reelVideo from "src/Assets/videos/reelFull.mp4";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import { STEP_ONE, STEP_TWO, STEP_FOUR, STEP_FIVE, STEP_SIX } from "src/Constants/Transitions";

import * as animationsActions from "src/Actions/animationsActions";

import Title from "src/Components/Tags/Title";
import TitleSection from "src/Components/Tags/TitleSection";
import Text from "src/Components/Tags/Text";

interface TemplateProps {
  face: number;
  faces: FacesType;
  currentFace: number;
  mutedShowreel: boolean;
  fullScreenStatus: FullScreenStatusType | null;
  setFullScreenStatus: (value: FullScreenStatusType | null) => void;
}

const Template = (props: TemplateProps) => {
  const { face, mutedShowreel, currentFace, fullScreenStatus } = props;
  const media = [reelVideo, adaptVideo, innovateVideo, tranformVideo, experienceVideo, immerseVideo];
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const faceShowreel = 0;
  const [promise, setPromise] = useState<Promise<void> | undefined>();

  const animationIn = useSpring({
    opacity:
      fullScreenStatus === null ||
      fullScreenStatus === STEP_ONE ||
      fullScreenStatus === STEP_TWO ||
      fullScreenStatus === STEP_FOUR ||
      fullScreenStatus === STEP_FIVE ||
      fullScreenStatus === STEP_SIX
        ? 0
        : 1,
    config: { duration: 500 },
  });

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      if (currentFace === face) {
        if (face === faceShowreel) video.currentTime = 0;
        setPromise(video.play());
      } else {
        if (promise !== undefined) {
          promise
            .then(() => {
              if (face === faceShowreel && currentFace !== faceShowreel) video.pause();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
  }, [currentFace]);

  return (
    <Box sx={styles.container}>
      <div style={{ position: "absolute", zIndex: -1, backgroundColor: "#000" }}>
        <video
          ref={videoRef}
          autoPlay={false}
          loop
          playsInline
          muted={face !== faceShowreel || (face === faceShowreel && mutedShowreel)}
          src={media[face]}
          style={{
            width: "100vw",
            objectFit: RDDIsMobile && face === faceShowreel ? "contain" : "cover",
            height: "100vh",
          }}
        />
      </div>
      {face !== faceShowreel && (
        <Box sx={{ height: `calc(100vh ${RDDIsMobile ? "- 7.37vh" : ""})` }}>
          <animated.div
            style={{
              height: "100%",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              ...animationIn,
              backgroundColor: "#00000099",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10} sx={{ display: "flex", justifyContent: "center" }}>
                <Grid container spacing={2} sx={{ width: "80%", display: "flex", alignItems: "center" }}>
                  <Grid item xs={12}>
                    <TitleSection id={`main_sections.face_${face}.subtitle`} color="#fff" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Title id={`main_sections.face_${face}.title`} color="#fff" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} sx={{ justifyContent: "center" }}>
                    <Box sx={styles.text}>
                      <Text id={`main_sections.face_${face}.text`} color="#fff" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </animated.div>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  container: {
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    color: "white",
  },
  containerVideo: {
    width: "100vw",
    position: "absolute" as CSSProperties["position"],
  },
  text: {
    pl: { xs: 0, sm: 0, md: 8, lg: 8, xl: 8 },
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
    mutedShowreel: state.appReducer.mutedShowreel,
    faces: state.animationsReducer.faces,
    currentFace: state.appReducer.currentFace,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setFullScreenStatus: animationsActions.setFullScreenStatus,
};

export default connectWithDispatch(Template, mapStateToProps, mapDispatchToProps);
