import React, { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import Carrusel from "./Carrusel";
import Mute from "./Mute";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import { Box } from "@mui/material";

import * as animationsActions from "src/Actions/animationsActions";

import { STEP_ONE, STEP_TWO, STEP_THREE, STEP_FOUR, STEP_FIVE, STEP_SIX } from "src/Constants/Transitions";

import MiniCube from "./MiniCube";

import { isMobile } from "react-device-detect";

interface BaseSectionsProps {
  fullScreenStatus: FullScreenStatusType | null;
  setMovements: (movements: DirectionType[]) => void;
  setFullScreenStatus: (value: FullScreenStatusType | null) => void;
}

const MainSections = (props: BaseSectionsProps) => {
  const { fullScreenStatus, setFullScreenStatus } = props;

  const handleClose = () => {
    setFullScreenStatus(STEP_FOUR);
  };

  useEffect(() => {
    if (fullScreenStatus === STEP_ONE) setTimeout(() => setFullScreenStatus(STEP_TWO), 800);
    if (fullScreenStatus === STEP_TWO) setTimeout(() => setFullScreenStatus(STEP_THREE), 500);
    if (fullScreenStatus === STEP_FOUR) setTimeout(() => setFullScreenStatus(STEP_FIVE), 500);
    if (fullScreenStatus === STEP_FIVE) setTimeout(() => setFullScreenStatus(STEP_SIX), 100);
    if (fullScreenStatus === STEP_SIX) setTimeout(() => setFullScreenStatus(null), 200);
  }, [fullScreenStatus]);

  const animatedProps = useSpring({
    opacity:
      fullScreenStatus === STEP_TWO || fullScreenStatus === STEP_THREE || fullScreenStatus === STEP_FOUR || fullScreenStatus === STEP_FIVE
        ? 1
        : 0,
    config: { duration: 500 },
  });

  return (
    <>
      {fullScreenStatus !== null && (
        <animated.div
          className="mobile-sections"
          style={{ ...(!isMobile ? { height: "100vh" } : { height: "100svh" }), ...styles.animatedStyle, ...animatedProps }}
        >
          <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
            <Mute />
            <Carrusel />
            <ArrowLeft />
            <ArrowRight />
            <MiniCube handleClose={handleClose} />
          </Box>
        </animated.div>
      )}
    </>
  );
};

const styles = {
  animatedStyle: {
    position: "absolute" as CSSProperties["position"],
    width: "100vw",
    zIndex: 1,
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    fullScreenStatus: state.animationsReducer.fullScreenStatus,
  };
};

const mapDispatchToProps = {
  setFullScreenStatus: animationsActions.setFullScreenStatus,
};

export default connectWithDispatch(MainSections, mapStateToProps, mapDispatchToProps);
