/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

type MediaLoadedType = HTMLVideoElement | HTMLImageElement | FontFace;

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

function preloadVideo(src: string) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.oncanplaythrough = function () {
      resolve(video);
    };
    video.onerror = function () {
      reject(src);
    };
    video.src = src;
  });
}

function preloadFont(fontUrl: string, fontName: string) {
  return new Promise((resolve, reject) => {
    const font = new FontFace(fontName, `url(${fontUrl})`);
    font
      .load()
      .then((loadedFont) => {
        (document as any).fonts.add(loadedFont);
        resolve(loadedFont);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const useImagePreloader = (imageList: MediaPreloadType[]) => {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);
  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) return;

      const imagesPromiseList: Promise<MediaLoadedType>[] = [];
      for (const i of imageList) {
        if (i.type === "video") imagesPromiseList.push(preloadVideo(i.src) as Promise<MediaLoadedType>);
        if (i.type === "img") imagesPromiseList.push(preloadImage(i.src) as Promise<MediaLoadedType>);
        if (i.type === "font") imagesPromiseList.push(preloadFont(i.src, i.name ?? "") as Promise<MediaLoadedType>);
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
};

export default useImagePreloader;
