import { Box } from "@mui/material";
import partners from "src/Assets/images/partners.png";

const PartnersLogo = () => {
  return (
    <Box sx={styles.container}>
      <img src={partners} alt="" style={{ width: "100%" }} />
    </Box>
  );
};

const styles = {
  container: {
    position: "absolute",
    width: { xs: "10px", sm: "16vw", md: "16vw", lg: "12.1vw", xl: "12.1vw" },
    zIndex: 100,
    bottom: 0,
    left: 0,
    marginBottom: "2.48vh",
    marginLeft: "2.7vw",
  },
};

export default PartnersLogo;
