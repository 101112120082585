import * as Types from "../Constants/Types";

const initialState: BaseReducerType = {
  isLoading: true,
  isMobile: false,
  isSidebarOpen: false,
};

type SetLoadingAction = { type: typeof Types.SET_IS_LOADING; value: boolean };
type SetIsMobileAction = { type: typeof Types.SET_IS_MOBILE; value: boolean };
type SetIsSidebarOpenAction = { type: typeof Types.SET_IS_SIDEBAR_OPEN; value: boolean };

type baseReducerAction = SetLoadingAction | SetIsMobileAction | SetIsSidebarOpenAction;

const baseReducer = (state = initialState, action: baseReducerAction) => {
  switch (action.type) {
    case Types.SET_IS_LOADING: {
      return { ...state, isLoading: action.value };
    }
    case Types.SET_IS_MOBILE: {
      return { ...state, isMobile: action.value };
    }
    case Types.SET_IS_SIDEBAR_OPEN: {
      return { ...state, isSidebarOpen: action.value };
    }
    default:
      return state;
  }
};

export default baseReducer;
