import * as Types from "../Constants/Types";

const initialState: AppReducerType = {
  isOpenedBaseSections: false,
  currentSection: null,
  mutedShowreel: false,
  currentFace: 1,
  lunchReel: false,
  showInitialVideo: true,
  showCube: false,
};

type SetCurrentSectionAction = { type: typeof Types.SET_CURRENT_SECTION; value: CurrentSectionType };
type SetIsOpenedBaseSectionsAction = { type: typeof Types.SET_IS_OPENED_BASE_SECTIONS; value: boolean };
type SetMutedShowreelAction = { type: typeof Types.SET_MUTED_SHOWREEL; value: boolean };
type SetCurrentFaceAction = { type: typeof Types.SET_CURRENT_FACE; value: number };
type SetLunchReelAction = { type: typeof Types.SET_LUNCH_REEL; value: boolean };
type SetShowInitialVideoAction = { type: typeof Types.SET_SHOW_INITIAL_VIDEO; value: boolean };
type SetShowCubeAction = { type: typeof Types.SET_SHOW_CUBE; value: boolean };

type appReducerAction =
  | SetCurrentSectionAction
  | SetIsOpenedBaseSectionsAction
  | SetMutedShowreelAction
  | SetCurrentFaceAction
  | SetLunchReelAction
  | SetShowInitialVideoAction
  | SetShowCubeAction;

const appReducer = (state = initialState, action: appReducerAction) => {
  switch (action.type) {
    case Types.SET_CURRENT_SECTION: {
      return { ...state, currentSection: action.value };
    }
    case Types.SET_IS_OPENED_BASE_SECTIONS: {
      return { ...state, isOpenedBaseSections: action.value };
    }
    case Types.SET_MUTED_SHOWREEL: {
      return { ...state, mutedShowreel: action.value };
    }
    case Types.SET_CURRENT_FACE: {
      return { ...state, currentFace: action.value };
    }
    case Types.SET_LUNCH_REEL: {
      return { ...state, lunchReel: action.value };
    }
    case Types.SET_SHOW_INITIAL_VIDEO: {
      return { ...state, showInitialVideo: action.value };
    }
    case Types.SET_SHOW_CUBE: {
      return { ...state, showCube: action.value };
    }
    default:
      return state;
  }
};

export default appReducer;
