import React from "react";

import { Box, Stack } from "@mui/material";

import SquareButtons from "src/Components/MainAnimation/SquareButtons";
import CanvasThreeJS from "src/Components/MainAnimation/CanvasThreeJS";

const MainAnimation = () => {
  return (
    <>
      <Box sx={{ height: "100%" }}>
        <Stack sx={{ height: "100%", justifyContent: "space-around" }}>
          <Box sx={{ flex: 1, position: "relative", marginTop: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 } }}>
            <CanvasThreeJS />
          </Box>
          <SquareButtons />
        </Stack>
      </Box>
    </>
  );
};

export default MainAnimation;
