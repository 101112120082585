import * as Types from "../Constants/Types";

const initialFormState: FormType = {
  name: { value: "", error: false, helperText: "" },
  email: { value: "", error: false, helperText: "" },
  phone: { value: "", error: false, helperText: "" },
  organization: { value: "", error: false, helperText: "" },
  description: { value: "", error: false, helperText: "" },
};

const initialOptionsValues = [false, false, false, false, false, false, false, false, false];

const initialState: ContactReducerType = {
  form: initialFormState,
  formErrorGeneral: null,
  formIsLoading: false,
  optionsValues: initialOptionsValues,
  currentStep: 0,
};

type ResetFormAction = { type: typeof Types.RESET_FORM };
type SetFormAction = { type: typeof Types.SET_FORM; value: FormType };
type SetFormErrorGeneralAction = { type: typeof Types.SET_FORM_ERROR_GENERAL; value: FormErrorGeneralType };
type SetFormIsLoadingAction = { type: typeof Types.SET_FORM_IS_LOADING; value: boolean };
type SetOptionsValuesAction = { type: typeof Types.SET_OPTIONS_VALUES; value: boolean[] };
type SetCurrentStepAction = { type: typeof Types.SET_CURRENT_STEP; value: number };

type contactReducerAction =
  | ResetFormAction
  | SetFormAction
  | SetFormErrorGeneralAction
  | SetFormIsLoadingAction
  | SetOptionsValuesAction
  | SetCurrentStepAction;

const contactReducer = (state = initialState, action: contactReducerAction) => {
  switch (action.type) {
    case Types.RESET_FORM: {
      return { ...state, form: initialFormState, optionsValues: initialOptionsValues };
    }
    case Types.SET_FORM: {
      return { ...state, form: action.value };
    }
    case Types.SET_FORM_ERROR_GENERAL: {
      return { ...state, formErrorGeneral: action.value };
    }
    case Types.SET_FORM_IS_LOADING: {
      return { ...state, formIsLoading: action.value };
    }
    case Types.SET_OPTIONS_VALUES: {
      return { ...state, optionsValues: action.value };
    }
    case Types.SET_CURRENT_STEP: {
      return { ...state, currentStep: action.value };
    }
    default:
      return state;
  }
};

export default contactReducer;
