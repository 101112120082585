import React from "react";
import { Box, Stack } from "@mui/material";

import Btn from "./Btn";
import EmDash from "./EmDash";
import connectWithDispatch from "src/Hooks/connectWithDispatch";
import PartnersLogo from "src/Components/Base/PartnersLogo";

interface SquareButtonsProps {
  isMobile: boolean;
}

const SquareButtons = (props: SquareButtonsProps) => {
  const { isMobile } = props;
  const btnsFaces = [0, 1, 2, 3, 4, 5];

  return (
    <Box sx={{ position: "absolute", zIndex: 8, bottom: 0, width: "100%" }}>
      <Stack direction="column">
        <Box sx={{ display: "flex", justifyContent: "center", pb: "2.48vh" }}>
          <Stack direction="row">
            {btnsFaces.map((face) => {
              return (
                <React.Fragment key={`face_${face}`}>
                  <Btn face={face} />
                  {face === 5 ? null : <EmDash />}
                </React.Fragment>
              );
            })}
          </Stack>
        </Box>
        {isMobile && <PartnersLogo color={"BLACK"} />}
      </Stack>
    </Box>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    isMobile: state.baseReducer.isMobile,
  };
};

export default connectWithDispatch(SquareButtons, mapStateToProps);
