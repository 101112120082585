import { useState } from "react";

import { Box, Stack } from "@mui/material";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import Loading from "src/Components/Base/Loading";

import Title from "src/Components/Tags/Title";
import Text from "src/Components/Tags/Text";
import { useSpring, animated } from "@react-spring/web";

import Options from "src/Components/BaseSections/Contact/Options";

import Form from "src/Components/BaseSections/Contact/Form";
import Btns from "src/Components/BaseSections/Contact/Btns";

import * as contactActions from "src/Actions/contactActions";

import validateEmail from "src/Utils/validateEmail";

interface ContactProps {
  form: FormType;
  formIsLoading: boolean;
  currentStep: number;
  optionsValues: boolean[];
  setForm: (form: FormType) => void;
  sendForm: (form: { [key: string]: string }, options: string) => void;
  setCurrentStep: (value: number) => void;
  t: (key: string) => string;
}

const validations: FormValidationType = { name: ["required"], email: ["required", "email"], phone: ["required"] };

const Contact = (props: ContactProps) => {
  const { setForm, sendForm, t, formIsLoading, currentStep, setCurrentStep, form } = props;
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const backStep = () => setCurrentStep(0);

  const nextStep = () => setCurrentStep(1);

  const handleOnSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setWasSubmitted(true);

    const keys = ["name", "email", "phone", "organization", "description"];

    let newForm = {} as FormType;

    keys.forEach((key) => {
      const value = form[key].value;
      newForm = { ...newForm, ...runValidation(key, value) };
    });

    setForm(newForm);

    const hasErrors = Object.keys(newForm).some((key) => newForm[key].error);

    if (!hasErrors) {
      const values = Object.entries(newForm).reduce((result, [key, value]) => {
        result[key] = value.value;
        return result;
      }, {} as { [key: string]: string });

      const optionsForm = props.optionsValues.reduce((resultOptions, value, index) => {
        if (value) {
          const optionString = t(`sections.contact.steps.options.option_${index}`);
          resultOptions += resultOptions === "" ? optionString : `, ${optionString}`;
        }
        return resultOptions;
      }, "");

      sendForm(values, optionsForm);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name }: { value: string; name: string } = event.target;
    const newForm = { ...form };
    newForm[name] = { ...newForm[name], value };

    if (wasSubmitted) newForm[name] = (runValidation(name, value) as FormType)[name];

    setForm(newForm);
  };

  const runValidation = (key: string, value: string) => {
    let validated = { [key]: { ...form[key], value, error: false, helperText: "" } };
    if (validations[key] && validations[key].includes("email")) {
      const isValid = validateEmail(value);
      if (!isValid) validated = { [key]: { ...form[key], value, error: true, helperText: "email" } };
    }

    if (validations[key] && validations[key].includes("required"))
      if (!value) validated = { [key]: { ...form[key], value, error: true, helperText: "required" } };

    return validated;
  };

  const propsOptions = useSpring({
    opacity: currentStep == 0 ? 1 : 0,
    display: currentStep == 0 ? "block" : "none",
    width: "100%",
    config: { duration: 600 },
  });

  const propsForm = useSpring({
    opacity: currentStep == 1 ? 1 : 0,
    display: currentStep == 1 ? "block" : "none",
    width: "100%",
    config: { duration: 600 },
  });

  return (
    <Box sx={styles.container}>
      <Stack direction="column" sx={{ justifyContent: "space-between", alignItems: "flex-start", height: "100%", maxHeight: "585px" }}>
        <Box>
          <Loading show={formIsLoading} />
          <Title id={`sections.contact.title`} />
          <Box sx={{ mt: { xs: 1, sm: 2, md: 2, lg: 4, xl: 4 } }}>
            <Text id="sections.contact.subtitle" />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <animated.div style={{ ...propsOptions }}>
            <Options />
          </animated.div>
          <animated.div style={propsForm}>
            <Form handleOnChange={handleOnChange} wasSubmitted={wasSubmitted} />
          </animated.div>
        </Box>
        <Box>
          <Btns handleOnSubmit={handleOnSubmit} currentStep={currentStep} backStep={backStep} nextStep={nextStep} />
        </Box>
      </Stack>
    </Box>
  );
};

const styles = {
  container: {
    maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "70.4375vw", xl: "70.4375vw" },
    margin: { xs: "0", sm: "0 auto", md: "0 auto", lg: "0 auto" },
    mt: { xs: 5, sm: "0px", md: "0px", lg: "0px", xl: "0px" },
    width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
    padding: "0 5%",
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "flex-start",
    fontSize: "18px",
    color: "#000",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    form: state.contactReducer.form,
    formIsLoading: state.contactReducer.formIsLoading,
    currentStep: state.contactReducer.currentStep,
    optionsValues: state.contactReducer.optionsValues,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setCurrentStep: contactActions.setCurrentStep,
  setForm: contactActions.setForm,
  sendForm: contactActions.sendForm,
  resetForm: contactActions.resetForm,
};

export default connectWithDispatch(Contact, mapStateToProps, mapDispatchToProps);
