/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/display-name */
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const connectWithDispatch = (Component: any, mapStateToProps?: any, mapDispatchToProps?: any) => {
  const Connect = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Component));
  // @ts-ignore
  return (props: any) => <Connect {...props} dispatch={useDispatch()} theme={useTheme()} />;
};

export default connectWithDispatch;
