import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import getMovementsTo from "src/Utils/getMovementsTo";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import * as animationsActions from "src/Actions/animationsActions";

import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";

import Fade from "@mui/material/Fade";

import { isMobile } from "react-device-detect";

interface BtnProps {
  face: number;
  faces: FacesType;
  movements: DirectionType[];
  setMovements: (movements: DirectionType[]) => void;
}

const Btn = (props: BtnProps) => {
  const { face, faces, movements, setMovements } = props;
  const backgroundColor = "transparent";
  const defaultColor = "#828282";
  const [isActive, setIsActive] = useState(face === faces.front && movements.length === 0);
  const [borderColor, setBorderColor] = useState<string>(defaultColor);
  const [open, setOpen] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const titleFaces = ["Showreel", "Adapt", "Innovate", "Transform", "Experience", "Immerse"];

  const handleOnClick = (face: number) => {
    if (face !== faces.front) setMovements(getMovementsTo(face, faces.front, faces.top));
  };

  const handleHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: number | null) => {
    setAnchorEl(event.currentTarget);
    setOpen(value);
    if (value === null) setBorderColor(defaultColor);
    else setBorderColor("#000");
  };

  useEffect(() => {
    setIsActive(face === faces.front && movements.length === 0);
  }, [faces.front]);

  return (
    <>
      {!isMobile && (
        <Popper sx={{ padding: 0 }} open={open !== null && open !== faces.front} anchorEl={anchorEl} placement={"top"} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Typography sx={{ p: 2, color: "#000", fontSize: "10px", letterSpacing: "2px", textTransform: "uppercase" }}>
                {titleFaces[face]}
              </Typography>
            </Fade>
          )}
        </Popper>
      )}
      <Box
        onMouseEnter={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleHover(event, face)}
        onMouseLeave={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleHover(event, null)}
        onClick={() => handleOnClick(face)}
        sx={{ border: `0px solid ${backgroundColor}`, ...(isActive ? styles.boxOutsideActive : styles.boxOutside) }}
      >
        <Box
          sx={{
            backgroundColor: `${backgroundColor}`,
            ...(isActive ? styles.boxInsideActive : { ...styles.boxInside, border: `1px solid ${borderColor}` }),
          }}
        ></Box>
      </Box>
    </>
  );
};

const styles = {
  boxOutside: {
    transform: "rotate(45deg)",
    width: { xs: "5.435vw", sm: "3.8vw", md: "2.4vw", lg: "18px", xl: "18px" },
    height: { xs: "5.435vw", sm: "3.8vw", md: "2.4vw", lg: "18px", xl: "18px" },
    padding: { xs: "1.16vw", sm: "0.8vw", md: "0.4vw", lg: "2px", xl: "2px" },
    margin: { xs: "3.49vw", sm: "1.2vw", md: "0.8vw", lg: "8px", xl: "8px" },
    backgroundColor: "transparent",
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  boxInside: {
    width: "100%",
    height: "100%",
    transition: "all 0.3s ease",
  },
  boxOutsideActive: {
    transform: "rotate(45deg)",
    border: "3px solid #000",
    width: { xs: "5.435vw", sm: "3.8vw", md: "2.4vw", lg: "18px", xl: "18px" },
    height: { xs: "5.435vw", sm: "3.8vw", md: "2.4vw", lg: "18px", xl: "18px" },
    padding: { xs: "1.16vw", sm: "0.8vw", md: "0.4vw", lg: "2px", xl: "2px" },
    margin: { xs: "3.49vw", sm: "1.2vw", md: "0.8vw", lg: "8px", xl: "8px" },
    transition: "all 0.3s ease",
  },
  boxInsideActive: {
    backgroundColor: "#000",
    width: "100%",
    height: "100%",
    transition: "all 0.3s ease",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    faces: state.animationsReducer.faces,
    movements: state.animationsReducer.movements,
  };
};

const mapDispatchToProps = {
  setMovements: animationsActions.setMovements,
};

export default connectWithDispatch(Btn, mapStateToProps, mapDispatchToProps);
