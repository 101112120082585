import { Box, Stack, Grid } from "@mui/material";

import connectWithDispatch from "src/Hooks/connectWithDispatch";
import Title from "src/Components/Tags/Title";

const offices = [
  { key: 1, blocks: [1, 2, 3] },
  { key: 2, blocks: [1, 2, 3] },
];

const baseKey = "sections.office.office_";

interface OfficeProps {
  t: (key: string) => string;
}

const Office = (props: OfficeProps) => {
  const { t } = props;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.office}>
        {offices.map((office, index) => {
          return (
            <Stack
              key={index}
              sx={{
                ...styles.subContainer,
                ...(index === 1
                  ? {
                      borderTop: "1px solid #828282",
                      marginTop: { xs: "4vh", sm: "4vh", md: "4vh", lg: "6vh", xl: "6vh" },
                      paddingTop: { xs: "4vh", sm: "4vh", md: "4vh", lg: "6vh", xl: "6vh" },
                    }
                  : {}),
              }}
            >
              <Title id={`${baseKey + office.key}.title`} />
              <Grid container sx={{ mt: { xs: 2, sm: 2, md: 2, lg: 4, xl: 4 } }}>
                {office.blocks.map((block, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={6} lg={4} sx={{ mt: { sm: 4, md: 3, xs: 1 } }}>
                      <Box>
                        <span style={{ ...(block === 2 ? { fontWeight: 700 } : {}) }}>
                          {t(`${baseKey + office.key}.blocks.block_${block}.text_1`)}
                        </span>
                      </Box>
                      <Box>{t(`${baseKey + office.key}.blocks.block_${block}.text_2`)}</Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          );
        })}
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "70.4375vw", xl: "70.4375vw" },
    margin: { xs: "0", sm: "0 auto", md: "0 auto", lg: "0 auto" },
    width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
    padding: "0 5%",
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    fontSize: "18px",
    color: "#000",
  },
  office: {
    display: "flex",
    flexDirection: "column" as CSSProperties["flexDirection"],
    width: "100%",
  },
  subContainer: {
    display: "flex",
    flex: 1,
    justifyContent: { xs: "center", lg: "space-between" },
  },
};

export default connectWithDispatch(Office);
