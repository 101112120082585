import { useState, useEffect, useRef, Suspense, lazy } from "react";
import { Canvas } from "@react-three/fiber";
import { Preload } from "@react-three/drei";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import * as animationsActions from "src/Actions/animationsActions";

const CubePro = lazy(() => import("./CubePro"));
const PlaneBg = lazy(() => import("./PlaneBg"));

interface CanvasThreeJSProps {
  isMobile: boolean;
  showInitialVideo: boolean;
  movements: string[];
  cursorClass: string;
  setCursorClass: (cursorClass: string) => void;
  setMovements: (value: string[]) => void;
}

const CanvasThreeJS = (props: CanvasThreeJSProps) => {
  const { movements, cursorClass, setCursorClass, isMobile } = props;
  const [move, setMove] = useState<DirectionType | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isMouseInside, setIsMouseInside] = useState(false);

  const [nextMove, setNextMove] = useState<DirectionType | null>(null);

  useEffect(() => {
    const nextMovement = (movementIndex: number) => {
      setMove(movements[movementIndex] as DirectionType);
      if (movements[movementIndex + 1])
        setTimeout(() => {
          nextMovement(movementIndex + 1);
        }, 200);
      else props.setMovements([] as DirectionType[]);
    };

    if (movements.length > 0) nextMovement(0);
  }, [movements]);

  useEffect(() => {
    const isMouseInsideCanvas = (mouseX: number, mouseY: number) => {
      if (!canvasRef || !canvasRef.current) return false;

      const { left, top, width, height } = canvasRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const limitCanvas = windowHeight - (isMobile ? 150 : 75);

      return (
        limitCanvas > mouseY &&
        mouseX >= left &&
        mouseX <= left + width &&
        mouseY >= top &&
        mouseY <= top + height &&
        mouseX >= 0 &&
        mouseX <= windowWidth &&
        mouseY >= 0 &&
        mouseY <= windowHeight
      );
    };

    const handleMouseMove = (e: MouseEvent) => {
      setIsMouseInside(isMouseInsideCanvas(e.clientX, e.clientY));
    };

    const handleMouseLeave = () => setTimeout(() => setIsMouseInside(false), 2000);

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseout", handleMouseLeave);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseout", handleMouseLeave);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePointerMove = (event: any) => {
    const { clientX, clientY } = event;
    const { innerHeight, innerWidth } = event.view;

    const percentageH = (clientX * 100) / innerWidth;
    const percentageV = (clientY * 100) / innerHeight;

    const limitTop = 28;
    const limitBottom = 60;
    const limitLeft = 42;
    const limitRight = 57;

    let nextMove = null;
    let nextCursorClass = "";

    if (percentageV < limitTop) nextMove = "up";
    else if (percentageV > limitBottom) nextMove = "down";

    if (percentageH < limitLeft) nextMove = "left";
    else if (percentageH > limitRight) nextMove = "right";

    if (percentageH < limitLeft) nextCursorClass = "cursor-left";
    else if (percentageH > limitRight) nextCursorClass = "cursor-right";

    if (percentageV < limitTop) {
      if (percentageH < limitLeft && !isMobile) nextCursorClass = "cursor-up-left";
      else nextCursorClass = percentageH > limitRight && !isMobile ? "cursor-up-right" : "cursor-up";
    } else if (percentageV > limitBottom) {
      if (percentageH < limitLeft && !isMobile) nextCursorClass = "cursor-down-left";
      else nextCursorClass = percentageH > limitRight && !isMobile ? "cursor-down-right" : "cursor-down";
    }

    setNextMove(nextMove as DirectionType);
    if (cursorClass !== "coreCursor") {
      setCursorClass(nextCursorClass);
    }
  };

  const handleOnClick = () => {
    if (nextMove !== null) setMove(nextMove);
  };

  return (
    <Canvas
      style={{ background: "#fafafa" }}
      ref={canvasRef}
      flat
      linear
      onClick={handleOnClick}
      onPointerMove={handlePointerMove}
      className={cursorClass}
      dpr={[1, 0.9]}
    >
      <Suspense fallback={<></>}>
        <ambientLight intensity={4} />
        <CubePro move={move} setMove={setMove} isMouseInside={isMouseInside} />
        <PlaneBg />
      </Suspense>
      <Preload all />
    </Canvas>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    faces: state.animationsReducer.faces,
    movements: state.animationsReducer.movements,
    cursorClass: state.animationsReducer.cursorClass,
    showInitialVideo: state.appReducer.showInitialVideo,
    isMobile: state.baseReducer.isMobile,
  };
};

const mapDispatchToProps = {
  setMovements: animationsActions.setMovements,
  setCursorClass: animationsActions.setCursorClass,
};

export default connectWithDispatch(CanvasThreeJS, mapStateToProps, mapDispatchToProps);
