import { useState, useEffect } from "react";
import { Box, Stack, Button } from "@mui/material";

import Grid from "@mui/system/Unstable_Grid";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import connectWithDispatch from "src/Hooks/connectWithDispatch";

import "./index.css";

import logo_black from "src/Assets/images/CE_Logo_black.svg";
import logo_wide_black from "src/Assets/images/CE_Logo_wide_black.svg";

const links = [
  {
    title: "LinkedIn",
    url: "https://www.linkedin.com/company/charles-elena/",
  },
  {
    title: "Email",
    url: "mailto:enquiries@charleselena.com.au",
  },
  {
    title: "Instagram",
    url: "https://www.instagram.com/charleselenacreative/",
  },
];

interface FooterProps {
  isMobile: boolean;
  t: (key: string) => ReactNode;
}

const Footer = (props: FooterProps) => {
  const { t, isMobile } = props;
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 900);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth, isMobile]);

  return (
    <Box
      sx={{
        height: { xs: "auto", sm: "auto", md: "auto", lg: "160px", xl: "160px" },
        borderTop: "2px solid #000",
        ml: { xs: 0, sm: 1, md: 2, lg: 5, xl: 10 },
        mr: { xs: 0, sm: 1, md: 2, lg: 5, xl: 10 },
      }}
    >
      <Grid container sx={styles.containerGrid}>
        <Grid
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            height: { xs: "auto", sm: "auto", md: "100%", lg: "100%", xl: "100%" },
          }}
        >
          <Box sx={styles.logo}>
            <img style={{ width: "100%" }} src={smallScreen ? logo_wide_black : logo_black} alt="Charles Elena" />
          </Box>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={8}
          lg={8}
          sx={{ display: "flex", height: { xs: "auto", sm: "auto", md: "100%", lg: "100%", xl: "100%" } }}
        >
          <Box sx={styles.containerLinks}>
            <Stack direction={"column"}>
              <Stack direction={"row"} sx={{ alingItems: "center" }}>
                {links.map((link, index) => (
                  <Button
                    component="a"
                    target="_blank"
                    href={link.url}
                    color={"info"}
                    key={index}
                    className={"hover_link"}
                    sx={styles.link}
                    endIcon={<ArrowOutwardIcon sx={styles.arrow} className={"icon_hover"} />}
                  >
                    {link.title}
                  </Button>
                ))}
              </Stack>
              <Box sx={{ ...styles.copyright }}>{(t("footer.copyright") as string)?.replace("YYYY", new Date().getFullYear() + "")}</Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  containerGrid: {
    maxHeight: "157px",
    height: { xs: "6vh", sm: "10vh", md: "10vh", lg: "16.5vh", xl: "16.5vh" },
    maxWidth: "100vw",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    p: "2em 2em 2em 0em",
    fontWeight: 500,
    cursor: "default",
    userSelect: "none",
    width: { xs: "250px", sm: "250px", md: "200px", lg: "238px" },
    margin: { xs: "auto", sm: "auto", md: "0", lg: "0" },
    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
    alignItems: "center",
    justifyContent: { xs: "center", sm: "center", md: "center", lg: "flex-start" },
    display: "flex",
  },
  containerLinks: {
    pr: { xs: 0, sm: 0, md: 0, lg: "1em", xl: "1em" },
    display: "flex",
    justifyContent: { xs: "center", sm: "center", md: "center", lg: "flex-end" },
    flex: 1,
    alignItems: "center",
    height: "100%",
  },
  copyright: {
    fontSize: { xs: "10px", sm: "12px", md: "12px", lg: "16px" },
    textAlign: { xs: "center", sm: "center", md: "center", lg: "right" },
    pr: { lg: 4 },
    color: "#828282",
    width: "100%",
    userSelect: "none",
    mt: 1,
    display: { xs: "none", sm: "none", md: "none", lg: "block" },
  },
  link: {
    color: "#000",
    lineHeight: { xs: "27px", sm: "25px", md: "32px", lg: "36px" },
    fontSize: { xs: "18px", sm: "25px", md: "32px", lg: "36px" },
    p: 1,
  },
  arrow: {
    fontSize: { xs: "28px!important", sm: "33px!important", md: "43px!important", lg: "50px!important" },
  },
};
const mapStateToProps = (state: StateType) => {
  return {
    isMobile: state.baseReducer.isMobile,
  };
};

export default connectWithDispatch(Footer, mapStateToProps);
